@import "src/assets/css/theme";
.quote-address-salutation-label {
  padding: 10px;

  p {
    font-weight: bold;
  }
}

.quote-address-salutation-wrapper {
  overflow: hidden;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px 10px 10px;

  .quote-address-salutation-bg {
    border: 1px solid $grey-medium-light;
    display: inline-flex;
    background: $white;
    border-radius: 25px;
  }

  .quote-address-salutation {
    border-radius: 25px;
    background: $white;
    padding: 10px 25px;
    cursor: pointer;

    &:nth-child(1) {
      order: 1;
      margin-right: -18px;
    }

    &:nth-child(3) {
      order: 3;
      margin-left: -18px;
    }

    &:nth-child(1),
    &:nth-child(3) {
      z-index: 1;
    }

    &:nth-child(2) {
      order: 2;
      z-index: 2;
      border: 1px solid $grey-medium;

      &.active {
        border: 1px solid $grey-medium-light;
      }
    }

    &.active {
      z-index: 3;
      background: $grey-medium-light;
    }
  }

  .quote-address-old-salutation-hint {
    align-self: center;
    font-size: 0.9rem;
    margin-left: 10px;
  }

  .quote-address-delete-button {
    overflow: hidden;
  }
}
