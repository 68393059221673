#teo .free-item-input {
  .column {
    padding-bottom: 0;

    .control {
      float: right;
    }
  }

  input.input {
    text-align: initial;
    border-radius: 0;
    border-color: #dbdbdb;
    width: 100%;
  }

  .inputWarning {
    left: 10px;
  }

  .icon {
    width: 2.6em;
  }
}

.has-shopping-cart-warning {
  position: relative;
  .inputWarning {
    position: absolute;
    white-space: normal;
    min-width: 10em;
    text-align: left;
    background-color: #ef7b22;
    border-radius: 10px;
    padding: 10px;
    color: #000;
    z-index: 2;
    left: 0;
    top: 55px;

    &:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      border: 7px solid;
      left: 25px;
      top: -14px;
      border-color: transparent transparent #ef7b22 #ef7b22;
    }
  }
}
