@import "src/assets/css/theme";

.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  border-top: 1px solid $grey-medium-light;
  border-right: 1px solid $grey-medium-light;
  font-size: 12px;

  > span {
    background-color: white;
    padding: 8px 4px;
    border-left: 1px solid $grey-medium-light;
    border-bottom: 1px solid $grey-medium-light;
  }
}
