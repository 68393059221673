.print-options {
  .radio:not(:last-child) {
    width: 100%;
    margin-left: 0.5em;
    margin-bottom: 0.5em;
  }

  .checkbox:not(:last-child) {
    margin-bottom: 0.5em;
  }
}
