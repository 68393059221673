@import "src/assets/css/theme";

.cart-information {
  --flex-gap: 3px;

  display: flex;
  font-size: 12px;
  gap: calc(var(--flex-gap) * 2);

  .info-text {
    display: flex;
    gap: var(--flex-gap);
    align-items: center;

    &-value {
      color: $orange;
      font-weight: 700;
    }
  }

  &-columns {
    display: flex;
    justify-content: space-between;

    .system-info {
      color: $white;
    }
  }

  &-column {
    flex-grow: 0;
    padding: 0 0 0.5em 0;
  }
}
