.partial-order {
  display: flex;
  flex-direction: column;
  row-gap: 0.5rem;
  margin-left: 0.5rem;
  margin-bottom: 2rem;
}

.checkbox-wrapper {
  display: flex;
  column-gap: 0.5rem;
}

.all-part-order-checkbox {
  padding-top: 0.125rem;
}
