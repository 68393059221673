.expert-settings-details {
  margin-top: 48px;

  .expert-settings-summary {
    display: flex;
    justify-content: space-between;
    align-items: center;
    user-select: none;
    cursor: pointer;
  }

  &[open] {
    .expert-settings-icon {
      transform: scaleY(-1);
    }
  }
}
