.shopping-cart .free-item-modal {
  .modal-card-body {
    padding: 20px;
    .has-shopping-cart-warning .inputWarning {
      top: initial;
      bottom: -62px;
    }
  }
  .column {
    position: relative;
    .control {
      position: absolute;
      top: 2.5rem;
      right: 1rem;
      span {
        padding: 0;
      }
    }
  }
}
