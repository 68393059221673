@import "src/assets/css/theme";

#itemRowMenu {
  &.dropdown .dropdown-content .dropdown-item {
    white-space: nowrap;
    &:hover {
      background-color: $orange;
      color: $grey-dark;
    }
  }
}
