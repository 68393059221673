#teo .download-modal {
  .format {
    padding-top: 1em;
  }

  .checkbox-wrapper {
    display: flex;
    justify-content: flex-start;

    .checkbox {
      margin: 0.5em;
    }

    .small-text {
      padding-left: 0.5em;
      display: flex;
      align-self: center;
    }
  }
}
