@import "src/assets/css/theme";

/**
  * Warning: #teo is needed for priority reasons, because there is a global table config
  */
#teo .productView {
  background-color: $blue-light !important;
  height: 170px;
  cursor: auto !important;

  .columns {
    flex-wrap: wrap;

    .value-container {
      padding-right: 15px;
      font-size: 14px;
    }

    .fileFormatIcon {
      height: 15px;
      width: 15px;
    }

    .attributes-wrapper {
      display: flex;

      .attribute {
        flex-grow: 1;

        table {
          width: 100%;
        }

        .headline {
          font-size: 20px;
          padding-bottom: 0.5em;
        }

        a {
          color: black;
          text-decoration: underline;
        }
      }
    }

    .image {
      width: 100%;
      max-width: 170px;
    }

    .buttons {
      padding-top: 15px;
      flex-grow: 1;
      display: block;

      .button {
        width: 100%;
        margin-right: 0rem;
        white-space: normal;
        height: auto;

        .inputWarning {
          position: absolute;
          left: 0;
          top: 55px;
        }
      }
    }

    // IE11 hack
    _:-ms-lang(x),
    .attributes-wrapper {
      flex: 0 53%;
    }

    table tbody {
      width: 100%;

      & > tr {
        line-height: 20px;

        &:hover {
          background-color: transparent;
        }

        td {
          cursor: text;
          font-size: 14px;
          border: 0px;
          padding: 0em 0.75em 0em 0em;
        }
      }
    }
  }
}
