@import "src/assets/css/theme";

.parameter {
  height: 40px;
  display: flex;
  justify-content: space-between;
  align-items: center;

  .info-icon {
    display: flex;
    align-items: center;
    border: 0;
    padding: 0;
    margin-left: 0.5em;
    background-color: transparent;
    &:hover {
      cursor: pointer;
    }
  }

  .translated-name {
    display: flex;
    align-items: center;
  }

  &:first-child {
    flex: 1;

    a {
      padding-left: 0.5em;
    }
  }
}
