.differing-shipping-address {
  label {
    display: flex;

    input {
      height: 20px;
    }
  }

  .multi-column-input {
    label {
      padding-left: 10px;
    }
  }

  .differing-address-note {
    padding-left: 28px;
  }

  .differing-address-fields-opened {
    padding-top: 1rem;
  }

  .radio-forklift-check {
    display: flex;
    margin-top: 2rem;

    .radio {
      margin-left: 1rem;
    }
  }
}
