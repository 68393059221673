@import "src/assets/css/theme";

.react-datepicker__portal {
  .react-datepicker {
    border-radius: 0;
    border: unset;
    .react-datepicker__triangle {
      display: none;
    }
    .react-datepicker__month-container {
      .react-datepicker__header--custom {
        background-color: $orange;
        border-radius: unset;
        .customHeader {
          display: flex;
          justify-content: space-around;
          align-items: center;
          .button.is-text {
            text-decoration: none;
            &:hover,
            &:active,
            &:focus,
            &:visited {
              border-color: $grey-dark;
              background-color: $orange;
            }
            i {
              align-items: center;
              font-size: large;
            }
          }
          .react-datepicker__current-month {
            font-size: 1.25rem;
            color: $white;
          }
        }
        .react-datepicker__day-name {
          width: 2.5rem;
          line-height: 2.5rem;
          color: $white;
        }
      }
      .react-datepicker__month {
        .react-datepicker__week {
          .react-datepicker__day {
            width: 2.5rem;
            line-height: 2.5rem;
            &:hover {
              border-radius: 100%;
            }
          }
          .react-datepicker__day--selected {
            border-radius: 100%;
            background-color: $orange;
            border: 1px solid $orange;
          }
          .react-datepicker__day--today,
          .react-datepicker__day--keyboard-selected {
            border: 1px solid $orange;
            border-radius: 100%;
            background-color: unset;
            font-weight: unset;
            color: $orange;
            &.react-datepicker__day--selected {
              background-color: $orange;
              color: $white;
            }
          }
          .react-datepicker__day--outside-month {
            color: $grey-medium-light;
            pointer-events: none;
          }
        }
      }
    }
  }
}
