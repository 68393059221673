.max-width-50-percent {
  max-width: 50%;
}

.whitespace {
  padding-top: 1%;
  padding-bottom: 1%;
}

.top-bar {
  border-bottom-style: solid;
  border-bottom-color: grey;
  border-bottom-width: thin;
  padding-bottom: 18px;
  margin-bottom: 18px;

  input {
    margin-left: 3px;
  }
}
