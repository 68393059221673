@import "src/assets/css/theme";

#teo .modal-card-body {
  .input-head {
    padding-bottom: 30px;
    border-bottom: 1px solid $container-border;

    input.customer-exists,
    input.customer-exists:hover,
    input.customer-exists:focus {
      color: $error-red;
      border-color: $error-red;
    }
  }
  .quote-addresses {
    overflow: hidden;

    &.no-entries {
      color: $grey-medium-light;
      font-style: italic;
      padding: 20px 10px;
      border-top: 1px solid $container-border;
      border-bottom: 1px solid $container-border;
    }
  }

  .quote-address-input-header {
    display: flex;
    border-bottom: 1px solid $container-border;

    &.hide {
      display: none;
    }

    .quote-address-two-cells {
      &:nth-child(1) {
        flex: 1 95%;
      }

      &:nth-child(2) {
        text-align: center;
        align-self: center;
        flex: 1 5%;
        cursor: pointer;
      }
    }
  }

  .quote-address-wrapper {
    border-top: 1px solid $container-border;
    border-bottom: 1px solid $container-border;
    background-color: $grey-light;

    &:nth-child(1) {
      border-bottom: 0;
    }

    &:nth-child(2) {
      border-top: 0;
    }

    .quote-address-wrapper {
      border-top: 0;
    }

    &.quote-addresses-available {
      background-color: initial;
    }

    .quote-address-fields,
    .quote-address-inputs {
      display: flex;
      flex-flow: row wrap;
      padding: 10px 0;

      .flex-cell {
        padding: 0 10px;
        flex: 1 50%;

        p {
          font-weight: bold;
        }
      }
    }

    .quote-address-fields .flex-cell {
      flex: 1 33.33%;

      p span:nth-child(1) {
        margin-right: 5px;

        &:empty:after {
          content: "-";
        }
      }
    }

    .quote-address-salutation-label {
      padding: 10px;

      p {
        font-weight: bold;
      }
    }
  }
}
