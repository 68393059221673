.offer-management {
  .columns {
    .column.field.is-horizontal {
      flex: 1;
      white-space: nowrap;
    }

    .column.is-button {
      flex: 0;
    }
  }

  .checkbox-wrapper {
    display: flex;
    align-items: center;
    margin-left: 10px;
    margin-top: -20px;
    margin-bottom: 10px;

    .checkbox {
      height: 18px;
      width: 18px;
      min-width: 18px;
      margin-left: 15px;
    }
  }
}
