@mixin icon($class, $name) {
  .#{$class}-icon,
  .image-icon.#{$class} {
    &:after {
      background-image: url("../images/iconset/#{$name}.svg");
    }
  }
}

@include icon(delete, "Iconset_TEO_loeschen");
@include icon(reload, "Iconset_TEO_Lieferzeitenwechsel");
@include icon(exclamation-mark, "Iconset_TEO_zusatzinformation_kreis_blau");
@include icon(calc, "Iconset_TEO_rechner");
