$table-header: #1976d2;
$table-header-border: #1565c0;
$table-header-text-color: #fff;
$table-border: #d9d9d9;
$row-bg: #f4f2f1;
$row-hover: #f5f5f5;

.flex-table {
  width: 100%;

  div {
    box-sizing: border-box;
  }

  .flex-row {
    display: flex;
    flex-flow: row wrap;
    border-left: solid 1px $table-border;

    &.flex-row-head {
      &:first-of-type {
        border-top: solid 1px $table-header-border;
        border-left: solid 1px $table-header-border;
      }

      &:first-of-type .flex-cell {
        background: $table-header;
        color: $table-header-text-color;
        border-color: $table-header-border;
      }
    }

    &:hover {
      background: $row-hover;
    }

    &:nth-child(odd) {
      background: $row-bg;

      &:hover {
        background: $row-hover;
      }
    }
  }

  .flex-cell {
    flex: 1;
    white-space: pre-wrap;
    word-break: break-word;
    padding: 0.5em 0.5em;
    border-right: solid 1px $table-border;
    border-bottom: solid 1px $table-border;
  }
}
