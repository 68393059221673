@-webkit-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@-moz-keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.extendableImage {
  transition: width 0.25s ease-in;
  cursor: pointer;
  padding: 0;
  border: 0;

  & > .overlay {
    position: fixed;
    height: 100vh;
    width: 100vw;
    transition: width 0.25s ease-in;
    top: 0;
    left: 0;
    background-color: rgba(46, 49, 49, 0.7);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9;

    .bigImage {
      width: auto;
      max-height: 100%;
      max-width: 100%;

      -webkit-animation: fadein 0.1s ease-in;
      -moz-animation: fadein 0.1s ease-in;
      animation: fadein 0.1s ease-in;
    }
  }
}
