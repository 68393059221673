@import "src/assets/css/theme";

.product-selection-table {
  .no-products-found {
    margin-top: 0.5rem;
    margin-left: 0.65rem;
  }

  tr {
    th {
      &:first-of-type {
        width: 1rem;
      }
    }

    &:not(:hover) {
      .fa-star-o {
        opacity: 0;
      }
    }
  }

  .favorite-icon {
    padding: 0;
    border: 0;
    background: 0;
    cursor: pointer;
  }
}
