.modal-card-body.select-positions-tab {
  background-color: #eef4f4;
}

.slim-scrollbar {
  ::-webkit-scrollbar {
    width: 5px;
  }

  ::-webkit-scrollbar-thumb {
    background: #b5b5b5;
    border-radius: 10px;
    &:hover {
      cursor: default;
    }
  }
}

.select-positions-tab .lv-table {
  background-color: white;
  height: 100%;
  border: 2px solid #b5cacd;

  .vertical-divider {
    width: 2px;
    background-color: #b5cacd;
    &:is(
        [data-resize-handle-state="hover"],
        [data-resize-handle-state="drag"]
      ) {
      background-color: #ef7b22;
    }
  }

  .horizontal-divider {
    height: 2px;
    background-color: #b5cacd;
  }
}
