#teo {
  .article-info {
    .modal-card {
      max-width: 600px;

      .spare-part-long-text {
        white-space: normal;
        margin-top: 20px;
      }

      .modal-card-body {
        padding-left: 20px;
        padding-right: 20px;

        ul,
        ol {
          list-style: initial;
          padding-inline-start: 20px;
        }

        ol {
          list-style: decimal;
        }

        ul,
        ol,
        p {
          &:not(:last-child) {
            padding-bottom: 0.5em;
          }
        }
      }
    }
  }
}
