@import "src/assets/css/theme";

.customer-search {
  min-height: 520px;

  h3.subtitle {
    font-size: 1.1rem;
    margin-top: 1.5rem;
    margin-bottom: 0.5rem;

    &.address {
      margin-bottom: 1.5rem;
    }
  }

  .fa {
    padding-left: 5px;
  }

  .customer-search-fields {
    border-left: 1px solid $container-border;
    border-right: 1px solid $container-border;
  }

  .customer-search-header {
    width: 100%;
    position: relative;

    .icon.is-small {
      height: 100%;
      pointer-events: all;

      &:hover .fa-times {
        color: #292929;
      }

      &:hover .fa-search {
        cursor: initial;
      }
    }
  }

  .customer-search-input-field {
    border: 1px solid $container-border;
    padding: 0.5em;
    font-size: 1em;
    font-weight: 400;
    line-height: 1.5;
    width: 100%;
  }

  &.flex-table {
    .flex-row {
      border-left: none;
      border-bottom: 1px solid $container-border;
      background: none;

      &.customer-results:nth-child(odd) {
        background: none;
      }

      &.flex-row-head {
        &:first-of-type {
          border: none;
          align-items: center;

          &:hover {
            background: white;
          }
        }

        &:first-of-type .flex-cell {
          background: white;
          color: black;
          border: none;
        }

        .flex-cell {
          padding: 0;
          align-items: center;

          label {
            text-align: left;
          }
        }
      }

      &.flex-row-body {
        .flex-cell {
          line-height: 30px;
          border: none;

          .highlight-orange {
            color: $orange;
          }

          button {
            height: initial;
          }

          &.expand-cell {
            flex: 0 0 105px;
            padding: 0.5em;

            .expand-button {
              min-width: 89px;
              padding: 0.4em 0.4em 0.4em 0;

              div {
                padding-right: 0.3em;
              }
            }
          }
        }
      }
    }
  }
}
