#teo {
  .vis-container,
  .vis-modal {
    hr {
      margin: 0.5em auto !important;
    }
  }

  .vis-container {
    margin-top: 5.4rem;
    hr {
      margin: 0.5em auto !important;
    }
  }

  .modal-card {
    &.vis-modal {
      width: calc(100% - 50px);
      height: calc(100% - 50px);

      .modal-card-body {
        padding: 0;

        & > div {
          width: 100%;
          height: 100%;
        }
      }
    }

    &.vis-ar-modal {
      .vis-ar-modal-qr-code-container {
        display: flex;
        flex-direction: column;

        align-items: center;
        justify-content: center;

        height: 320px;

        img {
          width: 300px;
          height: 300px;
        }
      }
    }
  }
}
