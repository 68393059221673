@import "src/assets/css/theme";
.shopping-cart > .flex-table {
  .price-details {
    .flex-row.flex-row-body.price-details-row {
      background-color: $blue-light;
      border: 0;
      &:nth-child(odd) {
        background-color: $blue-light;
      }
      &:hover {
        background-color: $blue-light;
      }
      &:first-child {
        .flex-cell {
          border-top: $container-border;
          padding-top: 1rem;
          align-items: flex-start;
          word-break: keep-all;
        }
      }
      &:last-child {
        .flex-cell {
          font-weight: bold;
        }
      }
      &:nth-child(n + 2):nth-last-child(n + 3) .flex-cell {
        &.has-border {
          background-image: linear-gradient(
            to right,
            black 30%,
            rgba(255, 255, 255, 0) 40%
          );
          background-position: bottom;
          background-size: 3px 1px;
          background-repeat: repeat-x;
        }
      }
      &:nth-last-child(2) {
        .flex-cell.has-border {
          background: none;
          border-bottom: 1px solid $black;
        }
      }
      &:nth-child(n + 2) {
        margin-top: -0.5rem;
      }
      .flex-cell {
        border-top: 0;
        &:nth-child(n + 5) {
          min-width: 10%;
          white-space: inherit;
          justify-content: right;
        }
      }
    }
  }
}
