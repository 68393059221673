@import "icons";
@import "theme";
@import "~@encoway/react-components/sass/configurator";
@import "helpers";

html {
  overflow-anchor: none;
}

#teo {
  /* STANDARD */
  select::-ms-expand {
    display: none;
  }

  ::-webkit-input-placeholder {
    /* WebKit, Blink, Edge */
    color: $grey;
  }

  :-moz-placeholder {
    /* Mozilla Firefox 4 to 18 */
    color: $grey;
    opacity: 1;
  }

  ::-moz-placeholder {
    /* Mozilla Firefox 19+ */
    color: $grey;
    opacity: 1;
  }

  :-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $grey;
  }

  /*GENERAL*/
  body {
    background-color: $white;
  }

  .button:hover {
    border-color: $grey-dark;
    text-decoration: underline;
  }

  button {
    &.no-border {
      border: none;
    }

    &.transparent {
      background-color: transparent;
      border-color: transparent;
    }
  }

  .section {
    width: auto;
    padding: 0;
  }

  .section-less-padding-top {
    width: auto;
    padding: 0 1.5rem 3rem;
  }

  .is-orange {
    background-color: $orange !important;
  }

  .is-blue-dark {
    background-color: $blue-dark !important;
  }

  .is-blue-dark-color {
    color: $blue-dark !important;
  }

  .is-grey {
    color: $grey;
  }

  .boldText {
    font-family: "DIN-Medium", Arial, sans-serif;
    font-weight: bold;
  }

  .column {
    max-width: 100%;
    flex-basis: auto;
  }

  .label {
    padding-top: 0.5em;
  }

  .container {
    padding: 0px !important;
    width: 100%;
  }

  .custom-margin-1 {
    margin-bottom: 30px;
    margin-top: 30px;
  }

  .custom-margin-2 {
    margin-bottom: 30px;
  }

  .custom-margin-bottom-15 {
    margin-bottom: 15px;
  }

  footer a:after {
    content: "";
  }

  /*TABS*/
  .tabs {
    background-color: $black;
  }

  .tabs a {
    color: $white;
    border-width: 0px 0px 5px 0px;
    border-bottom-color: $black !important;
    padding: 0.25em 0.5em;
  }

  .tabs li.flex-active {
    flex: 1;
  }

  .tabs li.is-active a {
    background-color: $black;
    color: $white;
    border-bottom-color: $orange !important;
  }

  .tabs a:hover {
    background-color: $black;
    border-bottom-color: $white !important;
    color: $white !important;
    text-decoration: none;
  }

  .tabs li.is-disabled a {
    background-color: $grey-dark;
    border-bottom-color: $grey-dark !important;
    color: $grey;
    pointer-events: none;
  }

  /*IMAGEBUTTON*/
  .image-button img {
    border-bottom: 3px solid $black;
    cursor: pointer;
  }

  .image-button img.is-active {
    border-bottom: 3px solid $orange !important;
  }

  .image-button img:hover {
    border-bottom: 3px solid $white;
  }

  .image-button .column {
    padding: 10px;
  }

  /*BUTTON*/
  .button {
    border-radius: 0px;
    color: $black !important;
    .image-icon {
      padding-top: 0.5rem;
    }
  }

  .right-align-button {
    text-align: right;
  }

  .customExtendedRadioButton {
    border: none;
    background: transparent;
  }

  .button:focus:not(:active) {
    box-shadow: none;
    border-color: $orange;
  }

  .productView .button .fa {
    padding-left: 10px;
  }

  /*SEARCHTABLE*/
  .table thead td,
  .table thead th {
    color: $black;
  }

  .table thead tr {
    cursor: auto !important;
  }

  .table tr {
    cursor: pointer;
  }

  .table tr:hover {
    background-color: $grey-light;
  }

  .table thead tr:hover {
    background-color: transparent;
  }

  .table .selected {
    background-color: $grey-light;
  }

  .table.shoppingcart tr {
    cursor: auto;
  }

  .table.shoppingcart tr .fa {
    cursor: pointer;
    padding-left: 1px;
    padding-right: 1px;
  }

  .table.shoppingcart {
    font-size: 13px;

    > thead > tr,
    > tfoot > tr,
    > tbody > tr {
      .right {
        text-align: right;
      }

      .center {
        text-align: center;
      }

      .product-description {
        font-style: italic;
        max-width: 40em;
      }

      td {
        vertical-align: middle;

        &:first-child {
          cursor: move;
          cursor: grab;
          cursor: -moz-grab;
          cursor: -webkit-grab;
        }

        &:active {
          cursor: move;
          cursor: grabbing;
          cursor: -moz-grabbing;
          cursor: -webkit-grabbing;
        }
      }

      .cart-draggable {
        vertical-align: middle;
      }
    }
  }

  tr.tableAddRow:hover {
    background-color: transparent;
  }

  .tableAddRow .button {
    border: 1px solid $grey-light;
  }

  tr.tableSumRow:hover {
    background-color: transparent;
  }

  .sumBox label {
    padding-right: 1em;
  }

  .sumBox input {
    padding-right: 1em;
  }

  .cart-article-input-additional-information {
    padding-right: 10px;
  }

  .cart-comment-container textarea {
    height: 20rem;
    width: 30rem;
    padding: 0.5rem;
  }

  span.cartLabel {
    line-height: 33px;
  }

  div.cart-input-container,
  .cart-comment-container {
    position: relative;
  }

  .addProductButton .inputWarning:before,
  div.cart-input-container .inputWarning:before,
  .sec-warn:before {
    content: " ";
    width: 0;
    height: 0;
    border: 7px solid;
    left: 25px;
    position: absolute;
    top: -14px;
    border-color: transparent transparent #ef7b22 #ef7b22;
  }

  .sec-warn:before {
    border-color: transparent transparent #000 #000;
  }

  .addProductButton .inputWarning,
  div.cart-input-container .inputWarning,
  .sec-warn {
    position: absolute;
    white-space: normal;
    min-width: 10em;
    text-align: left;
    background-color: #ef7b22;
    border-radius: 10px;
    padding: 10px;
    color: #000;
    z-index: 2;
  }

  .sec-warn {
    display: none;
    min-width: 15rem;
    z-index: 101;
    left: -18px;
    background-color: #000;
    color: #fff;
    font-size: 1.3em;

    .buttons {
      margin-top: 1.5rem;
    }
  }

  .sec-warn.is-visible {
    display: block;
  }

  a.fa {
    color: #363636;
  }

  .warning-container {
    position: relative;
  }

  .tableSubGroup .pulledRightInSubGroup {
    padding-left: 20px;
  }

  .end-price-section p {
    margin-top: 5px;
    margin-bottom: 5px;
  }

  .end-price-section hr {
    margin-top: 0px;
    margin-bottom: 0px;
  }

  /*SEARCHPANEL*/
  .panel {
    border-radius: 0px;
  }

  .panel-icon .fa {
    font-size: 10px;
  }

  .panel-tabs a {
    padding-top: 40px;
  }

  .panel-tabs a:hover {
    border-bottom-color: $blue-dark !important;
  }

  .panel-tabs a.is-active {
    border-bottom-color: $orange !important;
  }

  .panel-block.is-active {
    color: $orange !important;
    font-weight: bold;
    border-left-color: $container-border;
  }

  .panel-block.is-active .panel-icon {
    color: $orange !important;
  }

  /*RESULTCONTAINER*/
  .smallText {
    font-size: 0.75rem;
  }

  .resultContainer hr {
    margin: 1px;
  }

  .resultContainer .calculationSymbol {
    padding-right: 5px;
    float: left !important;
  }

  .resultContainer .menu-list a:hover {
    background-color: transparent;
    cursor: auto;
  }

  .variantCondition-label {
    display: flex;
  }

  /*RESULTBUTTONCONTAINER*/
  .resultButtonContainer {
    margin-top: 10px;
  }

  .resultButtonContainer .button {
    margin-top: 10px;
    width: 100%;
    padding-top: 5px;
    padding-bottom: 5px;
  }

  .resultButtonContainer .button .is-pulled-left {
    margin-right: 10px;
  }

  /*SHOPPINGCARTBUTTONCONTAINER*/
  .cartButton {
    background-color: $grey-light;
  }

  .shoppingCartButtonContainerTop {
    margin-bottom: 50px;
  }

  .shoppingCartButtonContainerTop .button {
    margin: 0px 10px 10px 0px;
    padding: 5px;
    border: 1px solid $grey-light;
  }

  .shoppingCartButtonContainerTop .button .is-pulled-left {
    margin-right: 10px;
  }

  a.cartButton .image-icon {
    margin-left: 0.2em;
  }

  a.cartButton:hover {
    text-decoration: none;
    border: 1px solid;
  }

  .shoppingCartButtonContainerBottom {
    padding-top: 50px;
  }

  .shoppingCartButtonContainerBottom .button {
    margin: 0px 0px 10px 10px;
    padding-top: 5px;
  }

  .shoppingCartButtonContainerBottom .button .is-pulled-left {
    margin-right: 10px;
  }

  /*CONFIGURATOR*/
  .is-configurator {
    width: 100%;
    padding: 0px 0px;
  }

  .is-configurator .tabs {
    background-color: $white;
    border-bottom: 1px solid $black;
  }

  .is-configurator .tabs a {
    border: 1px solid $black;
    border-bottom-width: 3px;
    background-color: $black;
    margin-right: 10px;
    color: $white;
  }

  .is-configurator .tabs li.is-active a {
    background-color: $white;
    color: $black;
  }

  .is-configurator .tabs li.is-mandatory.is-active a:hover {
    color: $orange !important;
  }

  .is-configurator .tabs li.is-ready.is-active a:hover {
    color: $black !important;
  }

  .is-configurator .tabs li.is-mandatory a {
    color: $orange;
  }

  .is-configurator .fa-user {
    color: $orange;
  }

  .is-configurator .notification.is-warning {
    color: $black;
  }

  .is-configurator .is-loading::after {
    border-color: transparent transparent $orange $orange !important;
  }

  .button.is-progress.is-loading::after {
    border-color: transparent transparent $orange $orange !important;
  }

  .help.is-info {
    color: $orange;
  }

  .pagination-previous,
  .pagination-next {
    border-radius: 0px;
    font-size: 1em !important;
    color: $black !important;
    background-color: $blue-dark;
  }

  .pagination-previous:hover,
  .pagination-next:hover {
    border-color: $black;
    text-decoration: underline;
    &:enabled {
      cursor: pointer;
    }
  }

  .is-configurator .fa-times {
    color: $orange;
  }

  .is-configurator .fa-circle-o-notch {
    color: $orange;
  }

  .is-configurator .fa-check-square {
    color: $orange;
  }

  .is-configurator .fa-square-o {
    color: $orange;
  }

  .is-configurator .is-extended-radio-button {
    background: none;
    &:focus {
      border-color: transparent !important;
    }
    &:hover {
      border-color: $grey-medium-light !important;
    }
    &[disabled] {
      border-color: transparent;
    }
  }

  /*FILTER DROPDOWN*/
  .filters button {
    min-width: 125px;
  }

  .filters button.is-selected {
    border-color: $orange;
  }

  /*DROPDOWN*/
  .is-configurator .button:hover,
  .is-configurator .button:focus {
    border: 1px solid $grey-medium-light;
  }

  .dropdown .dropdown-item {
    cursor: pointer;
    padding-right: 1rem;
    border: none;
    background-color: white;
    width: 100%;
    white-space: normal;
    &:hover {
      color: $black;
      background-color: $grey-light;
    }
  }

  .dropdown .dropdown-item.is-not-selectable {
    color: $grey-medium-light !important;
  }

  .dropdown .dropdown-item.is-selectable:hover {
    background: $grey-light !important;
    color: $black !important;
  }

  .dropdown .dropdown-item.is-not-selectable:hover {
    background: $grey-light !important;
    color: $black !important;
  }

  .dropdown .dropdown-item.is-selected {
    color: $black !important;
    background-color: $orange !important;
  }

  .dropdown .dropdown-item.is-selected:hover {
    background: $orange !important;
    color: $white !important;
  }

  .dropdown-item span {
    vertical-align: middle;
  }

  /*DROPDOWNIMAGEBUTTON*/

  .flex-items {
    display: flex;
    flex-wrap: wrap;
  }

  .modal-card-width-drowpdown {
    width: auto !important;
    max-width: 75% !important;
  }

  .dropdown-imagebutton-item {
    background-color: $box-background-color;
    box-shadow: $box-shadow;
    color: $box-color;
    display: block;
    padding: $box-padding;
    margin: 5px;
    border-radius: 8px;
    border: none;
  }

  .dropdown-imagebutton-headline {
    font-size: 1rem;
    font-weight: 700;
    text-align: center;
  }

  .dropdown-imagebutton-subtext {
    @extend .smallText;
    text-align: left;
  }

  .dropdown-imagebutton-warning {
    border: 1px solid $orange;
  }

  .dropdown-imagebutton-warning:hover {
    border: 1px solid $orange;
  }

  /*INPUT*/
  .input {
    border-radius: 0px;
    border-color: $container-border;
  }

  .input:hover,
  .input:focus,
  .input.is-focused {
    border-color: $grey-medium-light;
  }

  .input.is-small,
  .textarea.is-small {
    border-radius: 0px;
    margin-left: 0px;
  }

  .input.is-warning,
  .textarea.is-warning {
    border-color: $orange;
  }

  .charcount {
    font-size: 10px;
    text-align: right;
  }

  /*./images*/
  .image-icon {
    position: relative;
    cursor: pointer;
    padding: 0.25em;
    display: inline-block;
  }

  .image-icon::after {
    background-size: 1.5em 1.5em;
    background-repeat: no-repeat;
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    content: "";
  }

  /* This is for overriding encoway/react-components class according
  *  to TT-414  / TEO-1184 in conjunction with newer versions of
  *  encoway/react-components >= 1.7.2
  */
  .image.is-max-256 {
    max-width: inherit;
    min-width: 256px;
  }

  .pluszeichen {
    cursor: default;
  }

  .pluszeichen::after {
    background-image: url("../images/iconset/Iconset_TEO_pluszeichen.svg");
  }

  .warenkorb-leer {
    display: inline-flex;
    align-items: center;

    &::after {
      background-image: url("../images/iconset/Iconset_TEO_randlos_warenkorb_leer.svg");
    }
  }

  .warenkorb-leeren::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_Position_loeschen.svg");
  }

  .warenkorb-voll::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_warenkorb_voll.svg");
  }

  .warenkorb-speichern::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_warenkorb_speichern.svg");
  }

  .warenkorb-laden::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_warenkorb_laden.svg");
  }

  .artikel-anlegen::after {
    background-image: url("../images/iconset/Iconset_TEO_postion_hinzu.svg");
  }

  .schraubenschluessel::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_Konfiguration.svg");
  }

  .vorgang-duplizieren::after {
    background-image: url("../images/iconset/Iconset_TEO_dokumente.svg");
  }

  .vorgang-reaktivieren::after {
    background-image: url("../images/iconset/Iconset_TEO_vorgang_reaktivieren.svg");
  }

  .angebot-drucken::after {
    background-image: url("../images/iconset/Iconset_TEO_angebot_drucken.svg");
  }

  .vorgang-teilen::after {
    background-image: url("../images/iconset/Iconset_TEO_vorgang_teilen.svg");
  }

  .vorgang-archivieren::after {
    background-image: url("../images/iconset/Iconset_TEO_vorgang_archivieren.svg");
  }

  .position-duplizieren::after {
    background-image: url("../images/iconset/Iconset_TEO_dokumente.svg");
  }

  .zeichnung-anfordern::after {
    background-image: url("../images/iconset/Iconset_TEO_stift_lineal.svg");
  }

  .artikel-kopieren::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_Konfiguration.svg");
  }

  .gruppe-anlegen::after {
    background-image: url("../images/iconset/Iconset_TEO_postion_hinzu.svg");
  }

  .excel-export::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_exceldatei_exportieren.svg");
  }

  .kalkulationsdetails::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_kalkulationsdetails.svg");
  }

  .angebotsdokument::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_angebotsdokument.svg");
  }

  .loeschen::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_Position_loeschen.svg");
  }

  .notiz::after {
    background-image: url("../images/iconset/Iconset_TEO_randlos_notiz.svg");
  }

  .lv-import::after {
    background-image: url("../images/iconset/Iconset_TEO_neuen_vorgang_erstellen.svg");
  }

  .neuen-vorgang-erstellen::after {
    background-image: url("../images/iconset/Iconset_TEO_neuen_vorgang_erstellen.svg");
  }

  .monitor {
    background-image: url("../images/iconset/Iconset_TEO_randlos_automatisch_gesetzt.svg");
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .fragezeichen {
    background-image: url("../images/iconset/Iconset_TEO_fragezeichen.svg");
    display: inline-block;
    width: 100%;
    height: 100%;
    background-position: center center;
    background-size: 460%;
  }

  .zauberstab {
    background-image: url("../images/iconset/Iconset_TEO_randlos_Zauberstab.svg");
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  .zusatzinformationen {
    background-image: url("../images/iconset/Iconset_TEO_randlos_zusatzinformation_kreis_blau.svg");
    display: inline-block;
    width: 100%;
    height: 100%;
  }

  /* TT-170/TT-179/TT-193: These rules are needed to override the intersecting rules of bulma and bootstrap
-----------------------------------------------------------------------------------------*/
  hr {
    background-color: rgb(219, 219, 219) !important;
    border-bottom-color: rgb(128, 128, 128) !important;
    border-bottom-style: none !important;
  }

  .sumBox div {
    line-height: 24px !important;
    font-size: 16px;
  }

  .modal .checkbox input {
    width: 13px;
    min-width: 13px;
  }

  .modal {
    z-index: 99999 !important;
    max-width: 100%;
    width: auto;
    font-size: 16px;
    background-color: transparent;
    padding: 0 0 0 0;
    line-height: 1.5 !important;
    text-align: left;
  }

  .modal .is-active {
    z-index: 99999;
  }

  .modal-card-foot {
    border-radius: 0px;
    justify-content: flex-end;
    min-height: 77px;
  }

  .modal-card-head .modal-card-notice {
    color: red;
    font-size: 0.75em;
    border: 2px solid red;
    vertical-align: center;
    padding-right: 5px;
    padding-left: 5px;
    margin-right: 20px;
  }

  .modal textarea {
    border-color: rgb(219, 219, 219);
  }

  .modal-content {
    width: auto !important;
  }

  .tabs a:focus {
    border-bottom-color: $white !important;
    color: $white !important;
    text-decoration: none !important;
  }

  .resultContainer .boldText {
    font-size: 16px !important;
    line-height: 24px !important;
  }

  .resultContainer .smallText {
    line-height: 18px !important;
  }

  /*-----------------------------------------------------------------------------------------*/

  /*MODAL DIALOG*/
  .create-a-new-quote {
    width: auto !important;
    max-width: 500px;
  }

  .modal-card-body .input::placeholder {
    font-style: italic;
  }

  .modal-card-body .checkbox input[type="checkbox"] {
    appearance: checkbox;
    -webkit-appearance: checkbox;
    margin-right: 5px;
  }

  .modal-card-body .bottom-space {
    margin-bottom: 20px;
  }

  .modal-card-body .input:focus {
    border-color: $orange;
  }

  .modal-card-body .textarea:hover {
    border-color: $grey-medium-light;
  }

  .modal-card-body .textarea:focus {
    border-color: $orange;
  }

  .modal-card-body .modal-card-subtitle {
    margin-left: 10px;
    margin-right: 10px;
  }

  .modal-card-body .checkbox {
    padding-left: 10px;
  }

  .modal-card-body .input,
  .textarea {
    border-radius: 0px;
  }

  .modal-card-body .with-padding {
    padding-left: 10px;
    padding-right: 10px;
  }

  .modal-card-body .two-columns {
    display: flex;
    flex-flow: row wrap;
  }

  .column-entry {
    flex-grow: 1;
    min-width: 200px;
    width: 50%;
  }

  .column-entry-slim {
    flex-grow: 1;
    min-width: 100px;
    width: 35%;
  }

  .column-entry-wide {
    flex-grow: 3;
    min-width: 200px;
    width: 65%;
  }

  .column-entry-full {
    flex-grow: 3;
    min-width: 200px;
    width: 100%;
  }

  .modal-card-head {
    border-radius: 0;
  }

  .modal-card-title {
    font-size: 2rem;
    flex-shrink: 1;
  }

  .modal-card-foot .fa {
    padding-left: 5px !important;
  }

  // 1070px is the point where the modal gets bigger with an applied width of
  // 60%, under 1070px the modal should stay at a width of 800px
  @media screen and (min-width: 1070px), print {
    .modal-content,
    .modal-card {
      width: 60%;
      margin: 0;
    }
    .field-label {
      flex-basis: auto;
    }
  }

  .modal-card {
    overflow: auto;

    &.fullwidth {
      width: 95vw;
    }
    &.fullheight {
      height: 95dvh;
    }
  }

  .categorySelection {
    margin-bottom: 30px;
  }

  .categorySelectionCards {
    display: flex;
    flex-wrap: nowrap;
  }

  .categorySelectionDropdown {
    background-color: $grey-light;
    border: 0;
    cursor: pointer;
    display: flex;
    justify-content: center;
    width: 100%;
  }

  .categorySelectionDropdown .icon {
    height: 1em;
  }

  .categorySelectionDropdown:hover {
    color: $orange;
  }

  .categorySelection .categorySelectionItem {
    border: 0;
    cursor: pointer;
    display: flex;
    flex-flow: column;
    flex-grow: 1;
    padding: 0;
    text-align: left;
    width: 20%;
    strong {
      color: #363636;
      font-weight: 700;
    }
  }

  .categorySelection .categorySelectionItem:hover {
    background-color: $grey-light;
  }

  .categorySelection .categorySelectionItem .is-active {
    color: $orange;
  }

  .is-configurator.is-sticky {
    div.container > .tabs {
      background-color: $white;
      width: inherit;
      max-width: inherit;
    }

    .tabs {
      position: fixed;
      z-index: 1;
    }
  }

  .is-max-256 {
    min-width: 256px;
  }

  .modal-container {
    max-width: 80%;
  }

  .is-source.is-sticky {
    height: 100%;

    .result {
      position: fixed;
      z-index: 1;
      overflow-y: auto;
      overflow-x: hidden;
      background-color: $white;
    }
  }

  /* PrintOptionsDialog*/
  .fieldWithoutMargin {
    .field:not(:last-child) {
      margin-bottom: 0;
    }
  }

  /*OFFER MANAGEMENT*/

  .table.offerManagement th {
    white-space: nowrap;
  }

  .table.offerManagement td {
    vertical-align: middle;
  }

  .table.offerManagement tr {
    cursor: auto;
  }

  .table.offerManagement tr.activeRow td {
    background-color: $orange;
  }

  .table.offerManagement tr.activeRowOtherUser td {
    background-color: $blue-light;
    color: $grey;
  }

  .table.offerManagement .icon {
    cursor: pointer;
  }

  .table.offerManagement .sortableColumn {
    padding-top: 0.375em;
  }

  .pagination-link {
    border-radius: 0px;
    cursor: pointer;
    &:not(.is-current) {
      background-color: $white;
    }
    &:hover {
      text-decoration: underline;
    }
  }

  .is-image-button p.caption {
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    left: 0px;
    right: 0px;
  }

  tr.is-group {
    background-color: $grey-light;
  }

  label.radio > span {
    margin-left: 0.25em;
  }

  label.radio > input {
    -webkit-appearance: radio;
    width: 1em;
    min-width: 1em;
  }

  .is-image .image {
    width: 100%;

    & img {
      object-fit: contain;
      width: auto;
      height: auto;
      max-width: inherit;
      max-height: inherit;
    }
  }

  .is-image {
    &.s {
      .image {
        &:last-child {
          max-height: 50px;
          max-width: 50px;
        }

        &:not(:last-child) {
          max-height: 32px;
          max-width: 32px;
        }
      }
    }

    &.m {
      .image {
        &:last-child {
          max-height: 80px;
          max-width: 80px;
        }

        &:not(:last-child) {
          max-height: 60px;
          max-width: 60px;
        }
      }
    }

    &.l {
      .image {
        &:last-child {
          max-height: 110px;
          max-width: 110px;
        }

        &:not(:last-child) {
          max-height: 88px;
          max-width: 88px;
        }
      }
    }

    &.xl {
      .image {
        &:last-child {
          max-height: 178px;
          max-width: 178px;
        }

        &:not(:last-child) {
          max-height: 150px;
          max-width: 150px;
        }
      }
    }
  }

  /*SHOPPING CART*/

  div.reconfigurationIsLoadingWarning {
    position: fixed; /* Sit on top of the page content */
    display: none; /* Hidden by default */
    width: 100%; /* Full width (cover the whole page) */
    height: 100%; /* Full height (cover the whole page) */
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5); /* Black background with opacity */
    z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  }

  div.reconfigurationIsLoadingWarningText {
    position: absolute;
    top: 40%;
    left: 30%;
    background-color: white;
    width: 400px;
    height: 200px;
    text-align: center;
    vertical-align: middle;
    line-height: 200px;
  }

  i.fa.fa-circle-o-notch.fa-spin.fa-fw {
    color: #ef7b22;
  }

  .validation-table td {
    vertical-align: middle;
  }

  .dropdown-menu {
    min-width: 13rem;
    .dropdown-divider {
      margin: 0.5rem 0;
    }
  }

  #lang {
    float: right;
    margin-right: 15px;
    font-size: large;
  }

  .gap-1 {
    gap: 0.25rem;
  }

  .gap-2 {
    gap: 0.5rem;
  }

  .gap-3 {
    gap: 0.75rem;
  }

  .gap-4 {
    gap: 1rem;
  }
}
