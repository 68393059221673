.button-row-footer {
  display: flex;
  border-top: solid 1px #d9d9d9;
  padding-top: 0.5em;
  padding-bottom: 0.5em;
  i {
    align-self: center;
    padding: 0.5em;
    &:after {
      cursor: pointer;
    }
  }
  .button {
    border: 1px solid #f7f7f7;
    margin: 0.3em;
    &:nth-last-child {
      margin-right: 0;
    }
  }
}
