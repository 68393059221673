@import "src/assets/css/theme";

.validate-cart-warning {
  width: auto !important;
  max-width: 500px;

  .loading {
    padding: 0.5em 2em 0.5em 0.5em;
  }

  .validation-error {
    color: $error-red;
    margin-left: 10px;
    margin-right: 10px;
    padding-top: 0.5em;
  }

  .flex-table {
    padding-top: 0.5em;

    .flex-row {
      border-left: 0;
      background: $white;

      &:last-child {
        .flex-cell {
          border-bottom: 0;
        }
      }

      .flex-cell {
        display: flex;
        border-left: 0;
        border-right: 0;
        align-items: center;

        &:nth-child(1) {
          flex: 2;
          justify-content: flex-start;
        }

        &:nth-child(2) {
          flex: 1;
          justify-content: center;
        }

        &:nth-child(3) {
          flex: 20;
          justify-content: flex-start;
        }
      }
    }
  }

  i.fa-times {
    font-size: 18px;
  }
}
