.configurator-pagination {
  .pagination-previous,
  .pagination-next {
    cursor: pointer;
    font-size: 1em;
    &:hover {
      text-decoration: underline;
    }
  }
}

.tab-container {
  display: flex;
  gap: 10px;

  .menu-item {
    align-items: center;
    background-color: rgb(0, 0, 0);
    border: 1px solid #000;
    border-bottom: 3px solid #000;
    box-sizing: border-box;
    color: rgb(239, 123, 34);
    cursor: pointer;
    font-size: 16px;
    font-weight: 400;
    line-height: normal;
    margin: 0px;
    outline: none;
    padding: 0.25em 0.5em;

    &.is-ready {
      color: #fff;
    }
    &.is-active {
      background-color: #fff;
      border-bottom-color: #ef7b22;
      color: #000;
    }
    &.is-mandatory {
      color: #ef7b22;
    }
  }
}
