#teo .calculation {
  padding-top: 2em;

  .inputWarning {
    position: absolute;
    white-space: normal;
    min-width: 10em;
    text-align: left;
    background-color: #ef7b22;
    border-radius: 10px;
    padding: 10px;
    color: #000;
    z-index: 2;
    left: 0;
    top: 55px;

    &:before {
      content: " ";
      position: absolute;
      width: 0;
      height: 0;
      border: 7px solid;
      left: 25px;
      top: -14px;
      border-color: transparent transparent #ef7b22 #ef7b22;
    }
  }

  .has-bottom-double-line {
    border-bottom: 6px double #dbdbdb;
  }

  hr {
    margin-top: 0.5em;
    margin-bottom: 0.5em;
  }

  .columns {
    display: flex;
    justify-content: flex-end;
    column-gap: 1em;
    align-items: center;
    margin: 0;

    &.calculation-box {
      @media all and (min-width: 1409px) {
        margin-right: 3.5em;
      }
      .column {
        padding: 0.25em 0.5em;
      }
    }

    .column.tax {
      align-items: center;
      gap: 5px;
      line-height: 1.8em;
    }
  }

  input {
    text-align: right;
  }

  button {
    font-size: 12px;
    height: 30px;
    margin-left: 10px;
  }
}
