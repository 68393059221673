@import "src/assets/css/theme";

.simple-table {
  display: block;
  font-size: 0.9rem;

  .width-100-percent {
    width: 100%;
  }

  .width-50-percent {
    width: 50%;
  }

  tr {
    &:nth-child(odd) {
      background-color: lightgray;
    }

    &:nth-child(even) {
      background-color: white;
    }

    &:hover {
      background-color: hotpink;
    }

    &:first-child {
      background-color: lightgray;
      border-bottom: 2px solid #292929;
    }

    td,
    th {
      border: 1px solid #292929;
      vertical-align: middle;
      padding: 5px;

      &.breakable-cell {
        max-width: 1px;
        p {
          word-wrap: break-word;
          hyphens: none;
        }
      }

      &:first-child,
      &:nth-child(2) {
        border-right: 1px solid #292929;
      }
    }
  }
}
