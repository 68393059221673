@import "theme";

#teo {
  .h2 {
    font-size: 26px;
    margin-bottom: 22px;
  }

  .overflow-y-always {
    overflow-y: scroll;
  }

  .error-label {
    color: $error-red;
    font-size: 10px;
  }

  .has-pointer {
    cursor: pointer;
  }

  .no-hover-bg {
    background: none;

    &:hover {
      background: none;
    }
  }
}
