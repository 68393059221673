.customer-management .flex-table {
  margin-bottom: 1.5rem;

  .sortableColumn {
    display: flex;
    align-items: center;
    cursor: pointer;
    user-select: none;
  }

  .flex-row {
    border-left: none;

    &:nth-child(odd) {
      background: none;
    }

    &.flex-row-head {
      .flex-cell:last-child {
        padding-left: 61px;
      }
      &:first-of-type {
        border: none;
        border-bottom: 1px solid #dbdbdb;
        align-items: center;

        &:hover {
          background: white;
        }
      }
      &:first-of-type .flex-cell {
        background: white;
        color: black;
        border: none;
        font-weight: bold;
      }
    }

    &.flex-row-body {
      .flex-cell {
        display: flex;
        align-items: center;
        border: none;
        border-top: 1px solid #dbdbdb;

        .small-text {
          font-size: 10px;
        }

        .comment-icon-wrapper {
          flex: 1;
          display: flex;
          justify-content: flex-end;
          padding-right: 5px;

          .icon {
            cursor: pointer;
          }
        }
      }
    }

    .flex-cell:nth-child(3) {
      flex: 0;
    }
  }
}
