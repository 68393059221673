@import "src/assets/css/theme";

.shopping-cart > .flex-table {
  color: rgb(54, 54, 54);
  font-size: 13px;

  .is-bold {
    font-weight: 700;
  }
  .overlay {
    align-items: center;
    background-color: rgba(255, 255, 255, 0.6);
    display: flex;
    inset: 0;
    justify-content: center;
    position: absolute;
    z-index: 1;
  }
  .flex-row {
    border-left: none;
    position: relative;

    &.flex-row-body.loading-spinner-row {
      height: 5rem;
      align-items: center;
      border-top: 1px solid $container-border;
      .flex-cell.loading-spinner-cell {
        align-items: center;
        border-top: 0;
        display: flex;
        justify-content: center;
        max-width: inherit;
        min-width: inherit;
      }
    }

    &.flex-row-foot {
      border-top: 1px solid $container-border;

      .footer-row {
        width: 100%;
      }
    }

    &.flex-row-body.flex-row-foot {
      border-bottom: 6px double $container-border;
    }

    &:nth-child(odd) {
      background: none;
    }

    &.flex-row-head {
      width: 100%;
      font-size: 13px;

      &:first-of-type {
        border: none;
        border-bottom: 1px solid $container-border;
        align-items: flex-start;

        &:hover {
          background: inherit;
        }

        .flex-cell {
          align-items: center;
          justify-content: flex-end;
          text-align: right;
          word-break: keep-all;
          display: flex;
          background: transparent;
          color: $black;
          border: none;
          font-weight: bold;

          &:nth-child(1),
          &:nth-child(2),
          &:nth-child(3),
          &:nth-child(4),
          &:nth-child(6) {
            justify-content: start;
          }

          &:nth-child(5) {
            text-align: center;
          }
        }
      }
    }

    &.flex-row-body {
      &:hover {
        background: #f5f5f5;
      }

      .flex-cell {
        display: flex;
        align-items: center;
        border: none;
        border-top: 1px solid $container-border;

        .small-text {
          font-size: 10px;
        }
      }

      &.is-active {
        background: $orange;
      }

      &.is-active-different-user {
        background: $blue-light;
        color: $grey-medium-light;
      }
    }

    .flex-cell {
      text-align: right;
      padding: 6px 6px;
      justify-content: flex-end;

      .input.table-input {
        text-align: right;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(4) {
        justify-content: start;
      }

      &:nth-child(1) {
        text-align: left;
        min-width: 25px;
        max-width: 25px;

        i {
          color: $orange;
        }
      }

      &:nth-child(2) {
        -webkit-user-drag: element;
        user-select: none;
        cursor: grab;
        cursor: -moz-grab;
        cursor: -webkit-grab;
        text-align: left;
        min-width: 50px;
        max-width: 50px;
      }

      &:nth-child(3) {
        text-align: left;
        min-width: 25px;
        max-width: 25px;

        i {
          color: $orange;
        }
      }

      &:nth-child(4) {
        text-align: left;
        min-width: 231px;
        max-width: 231px;

        .input.table-input {
          text-align: left;
        }
      }

      &:nth-child(5) {
        min-width: 55px;
        max-width: 55px;
        justify-content: center;
      }

      &:nth-child(6) {
        min-width: 69.5px;
        max-width: 69.5px;
        .shopping-cart-input-wrapper {
          width: 36.5px;
        }
        .shopping-cart-amount-unit {
          margin-left: 4px;
          word-break: keep-all;
          width: 17px;
        }
      }

      &:nth-child(7) {
        min-width: 80px;
      }

      &:nth-last-child(1) {
        min-width: 52px;
        max-width: 52px;
        &.expand-cell {
          max-width: 1000px;
        }
      }
    }

    @media all and (max-width: 1408px) {
      :not(.price-details-row) > .flex-cell {
        &:nth-child(n + 4) {
          border-top: 0;
        }

        &:nth-child(1) {
          order: -4;
          min-width: 5%;
          max-width: 5%;
        }

        &:nth-child(2) {
          order: -3;
          min-width: 5%;
          max-width: 5%;
        }

        &:nth-child(3) {
          order: -2;
          padding-right: 3.5%;
          min-width: 86%;
          max-width: 86%;
        }

        &:last-child {
          border-top: 1px solid $container-border;
          order: -1;
        }
      }
    }
  }
}
