@import "src/assets/css/theme";

#teo .product-selection-filter-wrapper {
  display: flex;

  .filter-wrapper {
    display: flex;
    justify-content: flex-start;
    flex-flow: row wrap;
    margin-bottom: 30px;
    width: 100%;

    .filters {
      padding-right: 10px;
    }
  }

  .input {
    &.is-selected {
      border: 1px solid $orange;
    }
  }

  .search-reset-wrapper {
    display: flex;
    flex-direction: column;
    min-height: 190px;

    & > .control {
      padding-right: 10px;
    }

    & > .product-search {
      min-width: 225px;
    }
  }
}
