.validate-cart-warning.update-price {
  min-width: 750px;
  max-width: 800px;
  .flex-table {
    .flex-row {
      &:nth-child(1) {
        div {
          font-weight: 600;
          border-bottom-width: 2px;
        }
      }

      .flex-cell {
        &.pricing-has-changed {
          font-weight: 600;
        }

        &:nth-child(1) {
          flex: 3;
        }

        &:nth-child(2) {
          flex: 2;
        }

        &:nth-child(3) {
          flex: 14;
        }

        &:nth-child(4),
        &:nth-child(5) {
          justify-content: flex-end;
          flex: 11;
        }
      }
    }
  }
}
