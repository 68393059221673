@import "src/assets/css/theme";

.modify-offer-modal {
  .fa-address-card {
    margin-left: 5px;
  }

  .offer-management-table-button {
    margin: -10px 10px 0 0;
    padding: 0 0 20px 0;
    position: relative;

    .input-warning {
      position: absolute;
      white-space: normal;
      top: -50px;
      right: 0;
      min-width: 10em;
      text-align: left;
      background-color: #ef7b22;
      border-radius: 10px;
      padding: 10px;
      color: #000;
      z-index: 99999999;

      &:before {
        content: "";
        position: absolute;
        width: 0;
        height: 0;
        border: 7px solid;
        right: 37px;
        bottom: -14px;
        border-color: #ef7b22 #ef7b22 transparent transparent;
      }
    }
  }

  .customer-header {
    position: relative;

    > p {
      position: absolute;
      left: 0;
      top: -22px;
      padding: 10px;
      background: $white;
      font-weight: bold;
      margin-bottom: 24px;
    }
  }

  .two-columns {
    width: 100%;
    .filler-item {
      content: "";
      flex: 50%;
      visibility: hidden;
    }
  }
}
