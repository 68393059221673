@import "src/assets/css/theme";

.save-buttons {
  padding: 0.75rem;
  min-height: 95px;

  .buttons:not(:last-child) {
    margin-bottom: 0;
  }

  .button {
    border: 1px solid $grey-light;

    &:disabled {
      border: 0;
    }

    &.highlighted {
      border: 1px solid red;
    }
  }

  .save-automatically-label {
    font-size: 12px;
    text-align: right;
  }
}
