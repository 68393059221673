// Initial variables
$grey-dark: #292929;
$grey-light: #f7f7f7;
$grey-medium-light: #b5b5b5;
$grey-medium: #f5f5f5;
$white: #ffffff;
$black: #000000;
$blue-dark: #adc6c9;
$blue-light: #d8eaeb;
$orange: #ef7b22;
$container-border: #dbdbdb;
$primary: $orange;
$warning: $orange;
$input-arrow: $orange !default;
$input-focus-border-color: $orange;
$input-focus-box-shadow-color: rgba($orange, 0.25) !default;
$error-red: red;

$pagination-current-background-color: $blue-dark;
$pagination-current-border-color: $blue-dark;
$pagination-current-color: $black;
$pagination-ellipsis-color: $grey-medium-light;
$pagination-hover-border-color: $grey-dark;

// Typography
$family-primary:
  DIN-Regular,
  -apple-system,
  BlinkMacSystemFont,
  "Segoe UI",
  "Roboto",
  "Oxygen",
  "Ubuntu",
  "Cantarell",
  "Fira Sans",
  "Droid Sans",
  "Helvetica Neue",
  "Helvetica",
  "Arial",
  sans-serif;
