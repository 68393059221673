@import "src/assets/css/theme";

.shopping-cart-cart-information {
  display: flex;
  text-align: right;
  font-size: 12px;

  .info-text-label {
    margin-right: 3px;

    &:not(:first-child) {
      margin-left: 3px;
    }
  }

  .shopping-cart-info-label {
    padding-right: 4%;
    flex: 1;
    align-self: end;

    .info-text-value {
      margin-right: 3px;
      color: $orange;
      font-weight: 700;

      &.highlighted {
        color: red;
      }

      &:last-child {
        margin-right: 0;
      }
    }
  }
}
