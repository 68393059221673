#teo {
  margin-bottom: 3rem;
}

input[type="checkbox"] {
  accent-color: #0075ff;
}

$sizes: 12;

@mixin margin-classes {
  @for $i from 1 through $sizes {
    $margin: $i * 2px;
    /* margin #{$margin} */
    .enc-m-#{$i} {
      margin: $margin;
    }
    .enc-ml-#{$i} {
      margin-left: $margin;
    }
    .enc-mr-#{$i} {
      margin-right: $margin;
    }
    .enc-mt-#{$i} {
      margin-top: $margin;
    }
    .enc-mb-#{$i} {
      margin-bottom: $margin;
    }
    .enc-mx-#{$i} {
      margin-left: $margin;
      margin-right: $margin;
    }
    .enc-my-#{$i} {
      margin-top: $margin;
      margin-bottom: $margin;
    }
  }
}
@include margin-classes;

@mixin padding-classes {
  @for $i from 1 through $sizes {
    $padding: $i * 2px;
    /* padding #{$padding} */
    .enc-p-#{$i} {
      padding: $padding;
    }
    .enc-pl-#{$i} {
      padding-left: $padding;
    }
    .enc-pr-#{$i} {
      padding-right: $padding;
    }
    .enc-pt-#{$i} {
      padding-top: $padding;
    }
    .enc-pb-#{$i} {
      padding-bottom: $padding;
    }
    .enc-px-#{$i} {
      padding-left: $padding;
      padding-right: $padding;
    }
    .enc-py-#{$i} {
      padding-top: $padding;
      padding-bottom: $padding;
    }
  }
}
@include padding-classes;
