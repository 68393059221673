.shopping-cart {
  .flex-cell {
    .price-details-head {
      text-align: right;
      font-size: 10px;
      text-decoration: underline;
      cursor: pointer;
      border: 0;
      background: 0;
      padding: 0;
      font-weight: bold;
    }
  }
}
