@import "src/assets/css/theme";

#teo {
  .reconfiguration-modal-header {
    p {
      font-size: 16px;
    }
  }

  .reconfiguration-modal {
    width: 55%;
    margin: 20px auto 0 auto;

    .reconfiguration-loading-label {
      align-self: center;
    }

    .reconfiguration-loading-state {
      flex: 0;
    }

    .reconfiguration-loading:not(:last-child) {
      border-bottom: 1px solid $container-border;
    }
  }
}
