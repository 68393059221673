@import "src/assets/css/theme";

.warning-box.box {
  color: $error-red;
  margin-bottom: 1rem;
  .warning-box-head {
    padding-right: 2px;
    text-decoration: underline;
  }
}
