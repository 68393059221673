@import "src/assets/css/theme";

.input-overlay {
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  &:hover input ~ .overlay {
    background-color: $grey-light;
  }

  input:checked ~ .overlay {
    background: $white;
  }
  input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  .overlay {
    border: 1px solid $grey-medium-light;
    position: absolute;
    top: -5px;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: $white;
    border-radius: 50%;
    &:after {
      margin: 5.5px;
      padding: 6px;
      border-radius: 50%;
      background: $orange;
      content: "";
      position: absolute;
      display: none;
      white-space: nowrap;
    }
  }
  input:checked ~ .overlay:after {
    display: block;
  }
}
