@import "src/assets/css/theme";

.generic-dropdown {
  &.is-fullwidth,
  &.is-fullwidth .dropdown-trigger,
  &.is-fullwidth .dropdown-trigger .button {
    width: 100%;
  }

  .dropdown-trigger .button {
    .selected-value-span {
      text-wrap: wrap;
      margin-right: auto;
    }
    .icon.is-small.has-text-orange {
      color: $orange;
    }
  }
}
