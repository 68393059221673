@import "src/assets/css/theme";

.offer-management-table > .flex-table {
  margin-bottom: 1.5rem;

  .sortable-column {
    display: flex;
    align-items: center;
    border: 0;
    background: 0;
    font-weight: bold;
    font-size: 14px;
    padding: 0;
    cursor: pointer;
  }

  .flex-row {
    border-left: none;

    &:nth-child(odd) {
      background: none;

      &:hover {
        background: $white;
      }
    }

    &.flex-row-head {
      &:first-of-type {
        border: none;
        border-bottom: 1px solid $container-border;
        align-items: center;

        &:hover {
          background: $white;
        }

        .flex-cell {
          display: block;
          background: $white;
          color: $black;
          border: none;
          font-weight: bold;

          @supports (-ms-ime-align: auto) {
            max-width: 160px;
          }

          &:nth-child(9) {
            flex: 0;
            min-width: 56px;
          }

          &:nth-child(8) {
            flex: 0;
            min-width: 30px;
          }
        }
      }
    }

    &.flex-row-body {
      .flex-cell {
        display: block;
        border: none;
        border-top: 1px solid $container-border;

        @supports (-ms-ime-align: auto) {
          max-width: 160px;
        }

        .small-text {
          font-size: 10px;
        }

        &:nth-child(9),
        &:nth-child(8) {
          flex: 0;
          min-width: initial;
          justify-content: center;

          .comment-icon-wrapper .icon {
            cursor: pointer;
            border: 0;
            background: 0;
          }
        }

        &:nth-child(9) {
          min-width: 56px;
        }

        &:nth-child(8) {
          min-width: 30px;
        }
      }

      &.is-active {
        background: $orange;
      }
      &.is-active-different-user {
        background: $blue-light;
        color: $grey-medium-light;
      }
    }
  }

  .flex-cell {
    overflow-wrap: break-word;
    word-wrap: break-word;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      flex: 0 1 165px;
    }

    &:nth-child(4),
    &:nth-child(5),
    &:nth-child(6) {
      flex: 3;
    }

    &:last-child,
    &:nth-last-child(2) {
      flex: 0;
    }

    @media screen and (min-width: 1024px) {
      &:nth-child(1) {
        flex: 0 1 165px;
      }

      &:nth-child(2),
      &:nth-child(3),
      &:nth-child(6) {
        flex: 0 1 12.5%;
      }

      &:nth-child(4),
      &:nth-child(5) {
        flex: 0 1 15%;
      }
    }
  }

  .offer-management-no-quotes-found > .flex-cell {
    border: none;
    border-top: 1px solid $container-border;
  }

  @media all and (max-width: 800px) {
    .flex-row.flex-row-body .flex-cell {
      flex: 1 100%;

      &:nth-child(1) {
        order: 1;
      }

      &:nth-child(2) {
        order: 2;
      }

      &:nth-child(3) {
        order: 3;
      }

      &:nth-child(4) {
        order: 5;
      }

      &:nth-child(5) {
        order: 6;
      }

      &:nth-child(6) {
        order: 7;
      }

      &:nth-child(7) {
        order: 8;
      }

      &:nth-child(8) {
        order: 8;
      }

      &:nth-child(9) {
        flex: 0;
        order: 4;
      }

      &:nth-child(1),
      &:nth-child(2),
      &:nth-child(3) {
        min-width: initial;
        flex: 1 calc(33.33% - 24px);
      }
    }
  }
}
