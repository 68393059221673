.delivery-time-change-check-modal-body {
  .button-label-wrapper {
    display: flex;
    align-items: center;
    justify-content: center;
    .button {
      margin-right: 20px;
    }
  }
  .failed-text {
    margin-bottom: 15px;
  }
  .failed-table {
    margin-left: 15px;
  }
}
