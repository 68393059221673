.modal {
  .modal-card-body {
    display: flex;
    flex-flow: column;
    padding: 12px;

    hr {
      height: 1px;
      margin: 24px 10px;
    }
  }
  .modal-card-foot {
    .mandatory-text {
      font-style: italic;
      margin-right: 10px;
    }
  }
}
