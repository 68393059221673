#teo .flex-cell {
  .warning-container {
    .sec-warn {
      top: 10px;
      right: 22px;
      left: auto;

      &::before {
        border-color: transparent #000 #000 transparent;
        top: -12px;
        right: 20px;
        left: auto;
      }
    }
  }
}
