.cart-view {
  .control {
    margin-top: 1em;
    .input-overlay {
      font-size: 14px;
      padding-left: 20px;
      line-height: 0.25;

      .overlay {
        height: 15px;
        width: 15px;

        &:after {
          padding: 5px;
          margin: 1px;
        }
      }
    }
  }
}
