.vis-container .vis-buttons {
  display: flex;
  flex-direction: row;
  gap: 1rem;

  align-items: center;
  justify-content: center;

  .button {
    flex: 0 0 40px;
    padding: 0;
    width: 40px;
  }
}
