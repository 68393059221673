#teo .article-info {
  width: 100%;

  .article-spare-part-info {
    flex-direction: row;
    justify-content: center;
    width: 100%;
    display: flex;
    position: relative;
  }

  .article-info-wrapper {
    width: 100%;
  }

  .article-info-spare-part-information-icon {
    cursor: pointer;
    align-self: center;
    font-size: 26px;
    border: 0;
    padding: 0;
    background: 0;

    .image-icon {
      padding: 0;
    }
  }

  .article-info-comment {
    cursor: pointer;
    border: 0;
    background: 0;
    padding: 0;
  }

  .article-properties {
    display: flex;
    flex-direction: column;
    row-gap: 3px;
  }

  .article-property {
    display: flex;

    :nth-child(1) {
      min-width: 4.5rem;
    }
  }

  .article-description {
    height: 1rem;
  }
}
