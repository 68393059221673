@import "src/assets/css/theme";
.shopping-cart > .flex-table {
  .product-details {
    .flex-row.flex-row-body.product-details-row {
      background-color: $blue-light;
      border: 0;
      &:nth-child(odd) {
        background-color: $blue-light;
      }
      &:hover {
        background-color: $blue-light;
      }
      .flex-cell {
        border-top: 0;
        &:nth-child(n + 5) {
          min-width: 10%;
          white-space: inherit;
          justify-content: right;
        }
        &.cell-expand {
          padding-right: 1.5em;
        }
      }
    }
  }
}
