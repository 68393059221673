@import "src/assets/css/theme";

.configuratorDropDownWrapper {
  .dropdown {
    display: flex;

    .dropdown-trigger {
      width: 100%;

      .control.button {
        width: 100%;

        .button-title {
          flex: 1;
        }
      }
    }

    #dropdown-menu.dropdown-menu {
      width: 100%;

      .dropdown-content {
        overflow-x: hidden;
        padding: 0;
        max-height: 400px;

        .dropdown-item {
          margin: 0;
          padding-bottom: 0;

          &.is-marked {
            color: $black;
            background-color: $grey-light;
          }
        }

        &.is-mandatory button {
          border: 1px solid $orange;
        }

        &.is-scrollable {
          overflow-y: scroll;
          // matches with height of position-row in product-column
          max-height: 247px;
        }
      }
    }

    .control.is-loading:after {
      right: 0.725em;
      top: 0.725em;
    }
  }
}
