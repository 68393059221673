@import "src/assets/css/theme";

#teo {
  #content > div {
    margin: 0 auto;
    max-width: 1344px;
    padding: 3rem 1.5rem;
  }

  .dev-login,
  .simulate-request {
    h1 {
      font-size: 120%;
    }

    .custom-headline {
      margin: 2%;
    }

    .button-with-margin-and-float-right {
      margin-left: 4px;
      margin-bottom: 4px;
      float: right;
    }

    .input-with-margin {
      margin-bottom: 6px;
      width: initial;
      min-width: initial;
      max-width: initial;
    }
  }

  .simulate-request {
    .custom-headline {
      margin: 4%;
    }

    .button-wrapper {
      margin-top: 6px;
      .button-with-margin-right {
        margin-right: 8px;
      }

      .request-was-successful {
        color: darkgreen;
      }

      .request-was-not-successful {
        color: $error-red;
      }
    }
  }
}
