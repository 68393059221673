@import "src/assets/css/theme";

.customer-search-address {
  width: 100%;

  hr.customer-search-break {
    margin-left: 0;
    margin-right: 0;
  }

  .quote-address-inputs {
    .flex-cell {
      padding: 0;
      &:nth-child(odd) {
        padding-right: 10px;
      }
      &:nth-child(even) {
        padding-left: 10px;
      }
    }
  }
  .flex-table {
    .radio-btn {
      flex: 0 0 51px;
      align-items: center;
    }

    .address-cell {
      padding: 0;
      flex: 1;
    }

    .flex-row {
      cursor: pointer;
      border: none;
      border-top: 1px solid $container-border;

      &.is-active {
        color: $orange;
      }

      .flex-cell {
        display: flex;
      }
    }

    .flex-table {
      padding: 0.3em 0;

      .flex-row {
        border: none;

        .flex-cell {
          padding: 0.2em;
          font-size: 0.9em;
        }
      }

      .flex-table {
        .flex-cell {
          border-top: 0;
        }
      }
    }
  }
}
